<template>
  <v-row class="mt_dec--40">
    <!-- Start Single Address  -->
    <v-col lg="4" md="4" sm="6" cols="12" class="mt--40">
      <div class="rn-address">
        <div class="icon">
          <i class="fas fa-phone"></i>
        </div>
        <div class="inner">
          <h4 class="title">{{ $t('message.27') }}</h4>
          <p>
            <a href="tel:+38 (068) 384 50 87"><a><b>{{ $t('message.21') }}</b>
                <br>
                +38 (068) 384 50 87
              </a>
            </a>
          </p>
          <p>
            <a href="tel:+38 (068) 132 10 28">
              <a><b>{{ $t('message.19') }}</b>
                <br>
                +38 (068) 132 10 28
              </a>
            </a>
          </p>
          <p>
            <a href="tel:+38 (093) 647 29 28"><a><b>{{ $t('message.32') }}</b>
              <br>
              +38 (093) 647 29 28
            </a>
            </a>
          </p>
        </div>
      </div>
    </v-col>
    <!-- End Single Address  -->

    <!-- Start Single Address  -->
    <v-col lg="4" md="4" sm="6" cols="12" class="mt--40">
      <div class="rn-address">
        <div class="icon">
          <i class="fas fa-envelope"></i>
        </div>
        <div class="inner">
          <h4 class="title">{{ $t('message.28') }}</h4>
          <p>
            <a href="mailto:info@drivezone.com.ua">
              <a><b>{{ $t('message.21') }}</b>
                <br>
                info@drivezone.com.ua
              </a>
            </a>
          </p>
          <p>
            <a href="mailto:bukovel@drivezone.com.ua">
              <a><b>{{ $t('message.19') }}</b>
                <br>
                bukovel@drivezone.com.ua
              </a>
            </a>
          </p>
        </div>
      </div>
    </v-col>
    <!-- End Single Address  -->

    <!-- Start Single Address  -->
    <v-col lg="4" md="4" sm="6" cols="12" class="mt--40">
      <div class="rn-address">
        <div class="icon">
          <i class="fas fa-map-marker-alt"></i>
        </div>
        <div class="inner">
          <h4 class="title">{{ $t('message.31') }}</h4>
          <p @click="goToBukovel">
            <a><b>{{ $t('message.21') }},</b>
              {{ $t('message.29') }}

            </a>
          </p>
          <p @click="goToDnipro">
            <a><b>{{ $t('message.19') }},</b>
              {{ $t('message.30') }}

            </a>
          </p>
        </div>
      </div>
    </v-col>
    <!-- End Single Address  -->
  </v-row>
</template>
<script>
export default {
  methods: {
    goToBukovel() {
      const url = 'https://maps.app.goo.gl/9XvWGAxGQPuTMjbK6';
      window.open(url, '_blank');
    },
    goToDnipro() {
      const url = 'https://maps.app.goo.gl/xtsnBqchFG2nwB678';
      window.open(url, '_blank');
    }
  }
};
</script>
